<template>
  <el-container>
    <el-main>
      <div class="container">
        <el-row :gutter="20">
          <el-col :span="16" :offset="4">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span style="font-weight: 600">修改昵称</span>
                </div>
                <el-form ref="form" :model="form" label-width="80px">
                  <el-form-item label="昵称">
                    <el-input
                        type="text"
                        placeholder="你的昵称"
                        v-model="form.nickname"
                        maxlength="16"
                        show-word-limit
                        style="width: 300px"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="onSubmit">保存修改</el-button>
                  </el-form-item>
                </el-form>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import axios from 'axios'

export default {
  name: "EditProfile",
  data() {
    return {
      form: {
        nickname: this.$store.state.userinfo.nickname ? this.$store.state.userinfo.nickname : ''
      }
    }
  },
  methods: {
    onSubmit() {
      if (this.form.nickname.trim() === '') {
        this.$message.error("昵称不能为空")
        return
      }
      axios({
        method: 'post',
        url: '/api/users/edit_nickname',
        data: this.form,
      }).then(r => {
        if (r.data.code === 0) {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        } else {
          this.$message.error(r.data.msg);
        }
      })
    }
  }
}
</script>

<style scoped>

</style>